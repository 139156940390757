<template>
  <div>
    <!-- <div class="bread-crumbs" style="margin-bottom: 10px">首页 &gt; 邀请函</div> -->
    <div class="title">
      <h4>邀请函</h4>
    </div>
    <div class="txt txt1">
      <p></p>
      <p style="text-align: center">
        <img
          src="@/assets/pc/HYC_PC.jpg"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvitationLetter",
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  /* padding: 25px 30px; */
}
.txt img {
  max-width: 100%;
}
</style>